import React from 'react';

import footerLogos from '../assets/footerLogos.png';
import logo from '../assets/footerLogo.png';

const Footer = () => {
  return (
    <div className="bg-[#2B3141] py-8">
        <div className=" grid grid-cols-1 lg:grid-cols-2 mx-[4%] w-[92%] flex justify-between items-center">
            <div className="text-xl text-white">
                <div className='py-[30px] lg:py-[80px] flex items-center justify-center md:justify-start'>
                    <img src={ logo } className='h-[59px] w-[228px] xl:w-[457px] xl:h-[115px]'></img>
                </div>
            </div>
            <div className="logo text-xl text-white flex h-[100%] items-center pb-4 justify-evenly">
                <img src={ footerLogos } className='lg:w-[735px] lg:h-[50px]'></img>
            </div>
        </div>
    </div>
  );
};

export default Footer;
