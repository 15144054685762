import React from 'react';

import hero from '../assets/hero.gif';

const Hero = () => {
  return (
    <section className="hero">
      <img className="image-container w-[100%]" src={ hero }></img>
    </section>
  );
};

export default Hero;
