import React from 'react';

const IconAndText = ({ icon, primaryText, secondaryText }) => {
  return (
    <div className='pt-[35px] lg:py-[60px] flex flex-row justify-center items-center'>
        <div>
        <img className='xl:h-[101px] xl:w-[101px] h-[51px] w-[51px]' src={ icon }></img>
        </div>
        <div className='pl-8'>
        <h1 className="font-['Abril_Fatface'] xl:text-[48px] text-3xl">{ primaryText }</h1>
        <h3 className="font-['Arimo'] text-[24px]">{ secondaryText }</h3>
        </div>
    </div>
  );
};

export default IconAndText;
