import React from 'react';

import ImageSlider from './ImageSlider';
import buildingIcon from '../assets/building.png';
import contentImage1 from '../assets/contentImage1.png';
import maximizeIcon from '../assets/maximizew.png';
import megaphoneIcon from '../assets/megaphonew.png';
import IconAndText from './IconAndText';
import Contact from './Contact';

const Content = () => {
  return (
    <section className="content">
      <div className="grid grid-cols-1 md:grid-cols-2 w-[100%] font-bold font-['Arimo'] ">
        <div 
          className="flex flex-col justify-center px-[32px] lg:pl-[85px] 2xl:pl-[145px] py-[32px] lg:py-[85px] 2xl:py-[145px]"
        >
          <h1 className='text-[30px] lg:text-[40px] xl:text-[70px] leading-[1.25] text-[#69B4DF]'>1600 SUMMER</h1>
          <h1 className='text-[30px] lg:text-[40px] xl:text-[70px] leading-[1.25] text-[#69B4DF]'>LIKE YOU'VE NEVER</h1>
          <h1 className='text-[30px] lg:text-[40px] xl:text-[70px] leading-[1.25] text-[#69B4DF]'>IMAGINED IT.</h1>
          <h3 className='font-normal 2xl:w-[605px] text-[16px] xl:text-[24px] pt-[20px] xl:pt-[90px] text-[#2C2C2C]'>
           1600 Summer is an essential part of the Stamford community
           with engaging amenities & retail connected to our campus & the greater neighborhood.</h3>
        </div>
        <div 
          className="image-container bg-cover h-[300px] lg:h-[462px] xl:h-[750.5px]" 
          style={{ backgroundImage: `url(${contentImage1})` }}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 w-[100%] bg-[#B91F24] text-white xl:gap-36 xl:px-28 place-content-center pb-[35px] lg:pb-[0px]">
        <IconAndText icon={maximizeIcon} primaryText="250,000 sf" secondaryText="of available space"/>
        <IconAndText icon={buildingIcon} primaryText="Full Building" secondaryText="opportunity"/>
        <IconAndText icon={megaphoneIcon} primaryText="Coming Soon" secondaryText="&nbsp;"/>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 w-[100%]">
        <ImageSlider />
        <div id='contact' className='flex flex-col pl-[32px] py-[32px] lg:pl-[85px] lg:py-[85px] 2xl:pl-[145px] 2xl:py-[145px] justify-center py-28'>
          <div>
            <h1 className="font-['Arimo'] text-[40px] xl:text-[64px] leading-[1.25] font-bold text-[#69B4DF] pb-[35px] xl:pb-[90px]">
              CONTACT US
            </h1>
          </div>
          <div className="font-['Arimo'] text-[15px] xl:text-[24px] grid grid-cols-1 md:grid-cols-2 xl:gap-16">
            <div>
              <Contact name="GREG FRISOLI" number="203.531.3605" email="gregory.frisoli@nmrk.com" />
              <Contact name="TOREY WALSH" number="203.531.3633" email="torey.walsh@nmrk.com" />
            </div>
            <div>
              <Contact name="FRED SMITH" number="212.372.1728" email="fred.smith@nmrk.com" />
              <Contact name="TIM RORICK" number="203.531.3622" email="tim.roricki@nmrk.com" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;