import React, { useState, useEffect } from 'react';

import imageSlider1 from '../assets/imageSlider1.png';
import imageSlider2 from '../assets/imageSlider2.png';
import imageSlider3 from '../assets/imageSlider3.png';

const ImageSlider = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    imageSlider1,
    imageSlider2,
    imageSlider3
  ];

  // Function to update current image index
  const updateImageIndex = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Effect to auto-rotate images
  useEffect(() => {
    const intervalId = setInterval(updateImageIndex, 2000); // Change image every 3 seconds
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <div className="relative h-[400px] lg:h-[505px] xl:h-[776px] overflow-hidden">
    {images.map((image, index) => (
      <div 
        key={ index }
        className={`image-container bg-cover absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          index === currentImageIndex ? 'opacity-100' : 'opacity-0'
        }`}
        style={{ backgroundImage: `url(${image})` }}
      />
      // <img
      //   key={ index }
      //   src={ image }
      //   alt={`Image ${ index + 1 }`}
      //   className={`image-container bg-cover absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
      //     index === currentImageIndex ? 'opacity-100' : 'opacity-0'
      //   }`}
      // />
    ))}
  </div>
  );
};

export default ImageSlider;
