import React from 'react';

const Contact = ({ name, number, email }) => {
  return (
    <div className='pb-[35px]'>
        <h1 className='font-bold'>{ name }</h1>
        <h3><a href={`tel:${ number.replace(/\./g, '') }`}>{ number }</a></h3>
        <h3><a href={`mailto:${ email }`}>{ email }</a></h3>
    </div>
  );
};

export default Contact;
