import React from 'react';

import logo from '../assets/logo.png';

const Header = () => {  
  // get the element by id and then scroll 
  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  return (
    <header className="bg-[#B91F24]">
      <div className="py-[20px] mx-[4%] w-[92%] flex justify-between items-center flex-col lg:flex-row">
        <div className="logo text-xl font-bold">
            <img src={ logo } className='h-[59px] w-[228px]'></img>
        </div>
        <nav>
          <ul className="pt-[20px] lg:pt-[0px] flex text-white text-[14px] lg:text-[24px] font-['Arimo']">
            <a href='https://maps.app.goo.gl/8ee1sVP2q5f6sMi29'>
              <h1 className="font-semibold">1600 Summer St, Stamford, CT 06905 &nbsp; </h1>
            </a>
            <h1> | </h1>
            <li>
              <a onClick={ scrollToContact } className="underline underline-offset-4 cursor-pointer px-3 py-2">Contact Us</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
